import React, {useState, useEffect} from 'react'
import { GoBack } from '../../../../../components/GoBack'
import { Container } from '../styles'
import scope3Service from '../../../../../services/scope3Service'
import { useHistory, useLocation } from 'react-router-dom'
import inventoryService from '../../../../../services/inventoryService'
import { TitleText } from '../../../../../components/TitleText'
import scopeService from '../../../../../services/scopeService'
import { formatPrice3 } from '../../../../../utils/transform'

export const EmissionsTotalsEffluents = () => {
    const history = useHistory();
    const location = useLocation();
    const [guidelines, setGuidelines] = useState<string | undefined>();
    const [inventorySelected, setInventorySelected] = useState<any>({});
    const [totalsEffluents, setTotalsEffluents] = useState<any>(null);
    const [title, setTitle] = useState<string | undefined>();

    useEffect(() => {
        const item:any = location?.state;
    
        if (item && item.company_id && item.year){
          getInventorysById(item.company_id, item.year)
        }else{
          history.push({
            pathname: '/inventario/escopo-3',
          });
        }
        getGuidelines()
      }, // eslint-disable-next-line react-hooks/exhaustive-deps
       [location]);
      
      async function getGuidelines() {
        const item = await scopeService.getBySubSlug(3, 'efluentes_gerados', 'emissoes_totais')
    
        setTitle(item.label)
        setGuidelines(item.guidelines)
  
      }


    async function getInventorysById(company_id: number, year: number) {
        const entity = await scope3Service.getTotalsEffluents(company_id, year)
        setTotalsEffluents(entity)

        const inventory = await inventoryService.getById(company_id, year)

        setInventorySelected(inventory)
    }
  return (
    <Container>
        <GoBack />
        <div style={{ display: "flex", flexDirection: "row", marginTop: 25, justifyContent: "space-between", marginBottom: 20 }} >
          <div style={{ display: "flex", flexDirection: "column" }} >
            {inventorySelected?.year && inventorySelected?.company && (
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <TitleText level={1} title={'Inventário: ' + inventorySelected?.year +' - ' + inventorySelected?.company?.corporate_name } />
                <TitleText level={4} title={'Escopo 03 - ' + title}  />
              </div>
            )}
          </div>
          <div style={{ display: "flex", flexDirection: "row" }} >
            {/* caso seja necessário adicionar */}
            {/* {guidelines && (
              <Button color="blue" size="md" type="button" onClick={handleOpenInfo}
                style={{ margin: 'auto 10px auto auto' }} >
                Orientações
              </Button>
            )} */}
          </div>
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />
        
        <div style={{ display: "flex", flexDirection: "column", marginRight: 30, marginBottom: 30}} >
          <span style={{textAlign: "start", fontSize: 16,  marginTop: 20, fontWeight: "bold"}}>
            Tabela 5 - Emissões Totais de Efluentes Líquidos
          </span>
        </div>

        {totalsEffluents && (
          <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                        width: "100%", borderColor: "#9E9E9E", height: "auto", 
                        display: "flex", flexDirection: "column", marginLeft: 0}} >
            <div style={{ width: "100%", display: "flex", flexDirection: "row"}} >
              <span style={{ margin: 10, width: "70%" }}>
                Emissões de CH₄ por tratamento e/ou disposição final de efluentes [tCH₄/ano]:
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(totalsEffluents.emission_ch4, false)}
              </span>
            </div>

            {totalsEffluents.emission_ch4 < 0 && (
              <div style={{ display: "flex", flexDirection: "row", color: "#C42329", 
                    fontSize: 12, fontWeight: "bold", marginLeft: 10 }} >
                  O dado de metano recuperado é maior que o de metano gerado. Revise seus cálculos.
              </div>
            )}
          </div>
        )}
        {totalsEffluents && (
          <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                        width: "100%", borderColor: "#9E9E9E", height: "auto", 
                        display: "flex", flexDirection: "column", marginLeft: 0}} >
            <div style={{ width: "100%", display: "flex", flexDirection: "row"}} >
              <span style={{ margin: 10, width: "70%" }}>
                Emissões de N₂O por tratamento e/ou disposição final de efluentes [tN₂O/ano]:
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(totalsEffluents.emission_n2o, false)}
              </span>
            </div>

            {totalsEffluents.emission_n2o < 0 && (
              <div style={{ display: "flex", flexDirection: "row", color: "#C42329", 
                    fontSize: 12, fontWeight: "bold", marginLeft: 10 }} >
                  O dado de remoção de nitrogênio como lodo é maior que a quantidade de nitrogênio no efluente gerado. Revise seus cálculos
              </div>
            )}
          </div>
        )}
        {totalsEffluents && (
          <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                        width: "100%", borderColor: "#9E9E9E", height: "auto", 
                        display: "flex", flexDirection: "column", marginLeft: 0}} >
            <div style={{ width: "100%", display: "flex", flexDirection: "row"}} >
              <span style={{ margin: 10, width: "70%" }}>
                Emissões em CO₂e por tratamento e/ou disposição final de efluentes [tCO₂e/ano]:
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(totalsEffluents.emission_co2e, false)}
              </span>
            </div>
          </div>
        )}
        {totalsEffluents && (
          <div style={{ borderStyle: "dashed", margin: 5, borderWidth: "1px", 
                        width: "100%", borderColor: "#9E9E9E", height: "auto", 
                        display: "flex", flexDirection: "column", marginLeft: 0}} >
            <div style={{ width: "100%", display: "flex", flexDirection: "row"}} >
              <span style={{ margin: 10, width: "70%" }}>
              Emissões em CO₂ biogênico por tratamento de efluentes [tCO₂/ano]:
              </span>
              <span style={{ fontWeight: "bold", margin: 10, textAlign: "right", width: "30%" }}>
                {formatPrice3(totalsEffluents.emission_co2_bio, false)}
              </span>
            </div>
          </div>
        )}
    </Container>
  )
}

