import React, {useEffect, useState} from "react";
import { Container } from "../styles";
import { GoBack } from "../../../../../components/GoBack";

export const EffluentsDomestic = () => {

    return (
        <Container>
            <GoBack />
            <div>Teste</div>
        </Container>
    )
}