import React, { useEffect, useState } from "react";
import { GoBack } from "../../../components/GoBack";
import { Container } from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import useAuth from "../../../hooks/useAuth";
import { SubmitHandler, useForm } from "react-hook-form";
import IpccService from "../../../services/ipccService";
import toast from "react-hot-toast";
import { formatPrice5 } from "../../../utils/transform";
import { TitleText } from "../../../components/TitleText";
import { Button } from "../../../components/Button/styles";

interface Inputs {
  name: string;
  nitrogen_content: any;
  reference: string;
}

export const IPCCEffluentsEdit = () => {
  const history = useHistory();

  const [ipcc2019Selected, setIpcc2019Selected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();

  useEffect(
    () => {
      const item: any = location?.state;

      if (item && item.ipcc_id) {
        getEffluentTypeById(item.ipcc_id);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const nitrogen_content = formData.nitrogen_content.replace(".", "");

      const newEffluent = {
        name: formData.name,
        reference: formData.reference,
        nitrogen_content: nitrogen_content
          ? parseFloat(nitrogen_content.replace(",", "."))
          : 0,
      };

      if (ipcc2019Selected?.id) {
        const result = await IpccService.updateEffluentTypeById(
          ipcc2019Selected.id,
          newEffluent
        );

        if (result && !result.error) {
          toast.success("Operação realizada com sucesso!");
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await IpccService.createEffluentType(newEffluent);

        if (result && !result.error) {
          toast.success("Operação realizada com sucesso!");
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getEffluentTypeById(id: number) {
    const effluentType = await IpccService.getByIdEffluentsType(id);

    setValue("name", effluentType.name);
    setValue("reference", effluentType.reference);
    setValue(
      "nitrogen_content",
      formatPrice5(Number(effluentType.nitrogen_content), false)
    );

    setIpcc2019Selected(effluentType);
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{5})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    return value;
  }

  return (
    <Container>
      <GoBack />
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 15,
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <TitleText level={1} title="Tipo de efluente" />

          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>

        <div
          style={{
            width: "100%",
            borderTop: "0.6px solid rgba(49, 54, 63, 0.35)",
          }}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            marginTop: 10,
            marginLeft: 50,
            marginRight: 50,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 10,
                width: "80%",
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#31363F",
                  opacity: "0.8",
                  fontWeight: "bold",
                }}
              >
                Nome
              </label>
              <input style={{ width: "95%" }} {...register("name")} />
              {errors.name && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>

            <div
              style={{ display: "flex", flexDirection: "column", width: "20%" }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#31363F",
                  opacity: "0.8",
                  fontWeight: "bold",
                }}
              >
                Teor de nitrogênio
              </label>
              <input
                style={{ width: "95%" }}
                {...register("nitrogen_content", { required: true })}
                onChange={(event) =>
                  setValue("nitrogen_content", currency(event))
                }
              />
              {errors.nitrogen_content && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
          </div>

          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                marginRight: 10,
                width: "33%",
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#31363F",
                  opacity: "0.8",
                  fontWeight: "bold",
                }}
              >
                Referência
              </label>
              <input
                style={{ width: "95%" }}
                {...register("reference", { required: true })}
              />
              {errors.reference && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
          </div>
        </div>
      </form>
    </Container>
  );
};
