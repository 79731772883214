import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/styles";
import { GoBack } from "../../components/GoBack";
import { Container } from "./styles";
import { useHistory } from "react-router-dom";
import useDebounce from "../../hooks/useDebounce";
import useAuth from "../../hooks/useAuth";
import IpccService from "../../services/ipccService";
import toast from "react-hot-toast";
import { TitleText } from "../../components/TitleText";
import { Pagination } from "../../components/Pagination";
import { ModalDelete } from "../../components/ModalDelete";
import scopeService from "../../services/scopeService";
import { Modal } from "../../components/Modal";
import Parser from "html-react-parser";

export const IPCCEffluents2006 = () => {
  const history = useHistory();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [ipcc2006, setIpcc2006] = useState<any>([]);
  const [ipcc2006Selected, setIpcc2006Selected] = useState<any>({});

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const searchDebounced = useDebounce(searchValue, 800);

  const [currentPage, setCurrentPage] = useState(1);
  const [openModal, setOpenModal] = useState(false);
  const [guidelines, setGuidelines] = useState<string | undefined>();
  const [title, setTitle] = useState<string>("");
  const [ipccInformation, setIpccInformation] = useState<any>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  async function getIPCC2006(page: number, filter: string) {
    const result = await IpccService.getByParams({
      limit: 15,
      page,
      filter,
    });
    setIpcc2006({
      ...result,
    });
  }

  useEffect(() => {
    getIPCC2006(1, searchDebounced);

    getGuidelines();
  }, []);

  useEffect(() => {
    getIPCC2006(1, searchDebounced);
    getGuidelines();
  }, [searchDebounced]);

  function redirectToEdit(ipcc?: any) {
    if (ipcc) {
      history.push({
        pathname: "/ipcc-efluentes-2006/edicao",
        state: { ipcc_first_id: ipcc.id },
      });
    } else {
      history.push("/ipcc-efluentes-2006/edicao");
    }
  }

  async function handleDeleteIPCC2006() {
    if (ipcc2006Selected) {
      const result = await IpccService.deleteById(ipcc2006Selected?.id);

      if (result) {
        setOpenModalDelete(false);
        getIPCC2006(1, searchDebounced);

        toast.success("Operação realizada com sucesso!");
      }
    }
  }

  async function getIPCC2006ById(id: number) {
    const ipcc2006 = await IpccService.getById(id);

    setIpcc2006Selected(ipcc2006);
  }

  function handleOpenModalDelete(ipcc2006?: any) {
    setOpenModalDelete(!openModalDelete);

    if (ipcc2006) {
      getIPCC2006ById(ipcc2006.id);
    } else {
      setIpcc2006Selected({});
    }
  }

  const goPrev = () => {
    setCurrentPage(currentPage - 1);
    getIPCC2006(currentPage - 1, searchDebounced);
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
    getIPCC2006(currentPage + 1, searchDebounced);
  };

  async function getGuidelines() {
    const item = await scopeService.getBySlug(6, "ipcc_2006");

    setTitle(item.label);
    setGuidelines(item.guidelines);
  }

  function handleOpenInfo() {
    setOpenModal(!openModal);
  }

  return (
    <Container>
      <GoBack />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 15,
          justifyContent: "space-between",
        }}
      >
        <TitleText level={1} title="IPCC 2006" />

        <input
          className="input-search"
          placeholder="Pesquisar tipos de efluentes IPCC 2006"
          onChange={(e) => setSearchValue(e.target.value)}
        />

        {guidelines && (
          <Button
            color="blue"
            size="md"
            type="button"
            onClick={handleOpenInfo}
            style={{ margin: "auto 10px auto 5px" }}
          >
            Orientações
          </Button>
        )}

        <Button color="blue" size="md" onClick={() => redirectToEdit()}>
          + Novo efluente
        </Button>
      </div>
      {ipcc2006?.itens && (
        <div
          style={{
            overflow: "hidden auto",
            position: "relative",
            marginTop: 10,
            height: "calc(100vh - 130px)",
            maxHeight: "calc(100vh - 130px)",
          }}
        >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr
                style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}
              >
                <th
                  style={{
                    textAlign: "start",
                    padding: "5px",
                    color: "#7A7D83",
                    fontWeight: "700",
                  }}
                >
                  {"Nome"}
                </th>
                <th
                  style={{
                    textAlign: "start",
                    padding: "5px",
                    color: "#7A7D83",
                    fontWeight: "700",
                  }}
                >
                  {"Descrição"}
                </th>
              </tr>
            </thead>
            <tbody>
              {ipcc2006.itens.map((item: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td style={{ padding: "10px", width: "50%" }}>
                        <span className="title-text-underline">
                          {item.name}
                        </span>
                      </td>
                      <td style={{ padding: "10px", width: "30%" }}>
                        <span>{item.description}</span>
                      </td>
                      <td>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <button
                            className="button-edit"
                            onClick={() => redirectToEdit(item)}
                          >
                            Editar
                          </button>
                          <button
                            className="button-delete"
                            style={{ marginRight: 5 }}
                            onClick={() => handleOpenModalDelete(item)}
                          >
                            Excluir
                          </button>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          {ipcc2006?.itens.length > 0 && (
            <Pagination
              total={ipcc2006.total}
              limit={15}
              goNext={goNext}
              goPrev={goPrev}
              currentPage={currentPage}
              length={ipcc2006.itens.length}
            />
          )}
        </div>
      )}

      <Modal
        isOpen={openModal}
        handleModalVisible={() => handleOpenInfo()}
        isPayOrTypeClient={true}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
                  <TitleText level={2} title="Orientações gerais" />
                </div>
        
                <div style={{ marginTop: 10, width: 550 }}>
                  {guidelines && (
                    <span style={{ fontSize: 12, textAlign: "justify" }}>
                      {Parser(guidelines)}
                    </span>
                  )}
                </div>
      </Modal>

      {openModalDelete && (
        <ModalDelete
          isOpen={openModalDelete}
          handleModalVisible={handleOpenModalDelete}
          entityName={ipcc2006Selected.name}
          handleOk={handleDeleteIPCC2006}
        />
      )}
    </Container>
  );
};
