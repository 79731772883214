import React, { useEffect, useState } from "react";
import { GoBack } from "../../../components/GoBack";
import { Container } from "./styles";
import { useHistory, useLocation } from "react-router-dom";
import { TitleText } from "../../../components/TitleText";
import { Button } from "../../../components/Button/styles";
import { SubmitHandler, useForm } from "react-hook-form";
import useAuth from "../../../hooks/useAuth";
import IpccService from "../../../services/ipccService";
import toast from "react-hot-toast";
import { formatPrice5 } from "../../../utils/transform";

interface Inputs {
  name: string;
  value_dqo: any;
  average: any;
}

export const IPCCEffluents2006Edit = () => {
  const history = useHistory();

  const [ipcc2006Selected, setIpcc2006Selected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();

  useEffect(
    () => {
      const item: any = location?.state;

      if (item && item.ipcc_first_id) {
        getEffluentById(item.ipcc_first_id);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [location]
  );
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const value_dqo = formData.value_dqo.replace(".", "");
      const average = formData.average.replace(".", "");

      const newEffluent = {
        name: formData.name,
        value_dqo: value_dqo ? parseFloat(value_dqo.replace(",", ".")) : 0,
        average: average ? parseFloat(average.replace(",", ".")) : 0,
      };

      if (ipcc2006Selected?.id) {
        const result = await IpccService.updateById(
          ipcc2006Selected.id,
          newEffluent
        );

        if (result && !result.error) {
          toast.success("Operação realizada com sucesso!");
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await IpccService.create(newEffluent);

        if (result && !result.error) {
          toast.success("Operação realizada com sucesso!");
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getEffluentById(id: number) {
    const effluentType = await IpccService.getById(id);

    setValue("name", effluentType.name);
    setValue("value_dqo", formatPrice5(Number(effluentType.value_dqo), false));
    setValue("average", formatPrice5(Number(effluentType.average), false));

    setIpcc2006Selected(effluentType);
  }

  function currency(e: React.FormEvent<HTMLInputElement>) {
    let value = e.currentTarget.value;
    value = value.replace(/\D/g, "");
    value = value.replace(/(\d)(\d{5})$/, "$1,$2");
    value = value.replace(/(?=(\d{3})+(\D))\B/g, ".");
    return value;
  }

  return (
    <Container>
      <GoBack />
      <form
        onSubmit={handleSubmit(onSubmit)}
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            marginTop: 15,
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          <TitleText level={1} title="Tipo de efluente" />
  
          <Button color="green" size="md" type="submit">
            Salvar
          </Button>
        </div>
  
        <div
          style={{
            width: "100%",
            borderTop: "0.6px solid rgba(49, 54, 63, 0.35)",
          }}
        />
  
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "90%",
            marginTop: 10,
            marginLeft: 50,
            marginRight: 50,
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              marginTop: 10,
              justifyContent: "space-between", 
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "30%", 
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#31363F",
                  opacity: "0.8",
                  fontWeight: "bold",
                }}
              >
                Nome
              </label>
              <input style={{ width: "100%" }} {...register("name")} />
              {errors.name && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
  
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "30%", 
                marginRight: "20%"
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#31363F",
                  opacity: "0.8",
                  fontWeight: "bold",
                }}
              >
                DQO (Demanda Bioquímica de Oxigênio)
              </label>
              <input
                style={{ width: "100%" }}
                {...register("value_dqo", { required: true })}
                onChange={(event) => setValue("value_dqo", currency(event))}
              />
              {errors.value_dqo && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div>
  
            {/* <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "30%",
              }}
            >
              <label
                style={{
                  marginTop: 5,
                  fontFamily: "'Roboto', sans-serif",
                  fontSize: "16px",
                  lineHeight: "19px",
                  color: "#31363F",
                  opacity: "0.8",
                  fontWeight: "bold",
                }}
              >
                Média
              </label>
              <input
                style={{ width: "100%" }}
                {...register("average", { required: true })}
                onChange={(event) => setValue("average", currency(event))}
              />
              {errors.average && (
                <span className="requiredLabel">Esse campo é obrigatório</span>
              )}
            </div> */}
          </div>
        </div>
      </form>
    </Container>
  );
};  
