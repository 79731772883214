import React, { useEffect, useState } from "react";
import { Button } from "../../components/Button/styles";
import { GoBack } from "../../components/GoBack";
import { Container } from "./styles";
import { TitleText } from "../../components/TitleText";
import IpccService from "../../services/ipccService";
import { useHistory } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import useDebounce from "../../hooks/useDebounce";
import { toast } from "react-hot-toast";
import { Pagination } from "../../components/Pagination";
import { ModalDelete } from "../../components/ModalDelete";

export const IPCCEffluents = () => {
  const history = useHistory();
  const [openModalDelete, setOpenModalDelete] = useState(false);
  const [ipcc2019, setIpcc2019] = useState<any>([]);
  const [ipcc2019Selected, setIpcc2019Selected] = useState<any>({});

  const [searchValue, setSearchValue] = useState<string | undefined>(undefined);
  const searchDebounced = useDebounce(searchValue, 800);

  const [currentPage, setCurrentPage] = useState(1);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { user } = useAuth();

  async function getIPCC2019(page: number, filter: string) {
    const result = await IpccService.getByParamsEffluentsType({
      limit: 25,
      page,
      filter,
    });

    setIpcc2019({
      ...result,
    });

    setCurrentPage(page);
  }

  useEffect(
    () => {
      getIPCC2019(1, searchDebounced);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  useEffect(() => {
    getIPCC2019(1, searchDebounced);
  }, [searchDebounced]);

  function redirectToEdit(ipcc?: any) {
    if (ipcc) {
      history.push({
        pathname: "/ipcc-efluentes/edicao",
        state: { ipcc_id: ipcc.id },
      });
    } else {
      history.push("/ipcc-efluentes/edicao");
    }
  }

  async function handleDeleteIPCC2019() {
    if (ipcc2019Selected) {
      const result = await IpccService.deleteEffluentsTypeById(ipcc2019Selected?.id);

      if (result) {
        setOpenModalDelete(false);
        getIPCC2019(1, searchDebounced);

        toast.success("Operação realizada com sucesso!");
      }
    }
  }

  async function getIPCC2019ById(id: number) {
    const ipcc2019 = await IpccService.getByIdEffluentsType(id);

    setIpcc2019Selected(ipcc2019);
  }

  function handleOpenModalDelete(ipcc2019?: any) {
    setOpenModalDelete(!openModalDelete);

    if (ipcc2019) {
      getIPCC2019ById(ipcc2019.id);
    } else {
      setIpcc2019Selected({});
    }
  }

  const goPrev = () => {
    setCurrentPage(currentPage - 1);
    getIPCC2019(currentPage - 1, searchDebounced);
  };

  const goNext = () => {
    setCurrentPage(currentPage + 1);
    getIPCC2019(currentPage + 1, searchDebounced);
  };

  return (
    <Container>
      <GoBack />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          marginTop: 15,
          justifyContent: "space-between",
        }}
      >
        <TitleText level={1} title="IPCC 2019" />

        <input
          className="input-search"
          placeholder="Pesquisar tipos de efluentes IPCC 2019"
          onChange={(e) => setSearchValue(e.target.value)}
        />

        <Button color="blue" size="md" onClick={() => redirectToEdit()}>
          + Novo efluente
        </Button>
      </div>

      {ipcc2019?.itens && (
        <div
          style={{
            overflow: "hidden auto",
            position: "relative",
            marginTop: 10,
            height: "calc(100vh - 130px)",
            maxHeight: "calc(100vh - 130px)",
          }}
        >
          <table style={{ width: "100%", borderCollapse: "collapse" }}>
            <thead>
              <tr
                style={{ borderBottom: "0.6px solid rgba(49, 54, 63, 0.35)" }}
              >
                <th
                  style={{
                    textAlign: "start",
                    padding: "5px",
                    color: "#7A7D83",
                    fontWeight: "700",
                  }}
                >
                  {"Nome"}
                </th>
                <th
                  style={{
                    textAlign: "start",
                    padding: "5px",
                    color: "#7A7D83",
                    fontWeight: "700",
                  }}
                >
                  {"Descrição"}
                </th>
              </tr>
            </thead>
            <tbody>
              {ipcc2019.itens.map((item: any, index: number) => {
                return (
                  <React.Fragment key={index}>
                    <tr>
                      <td style={{ padding: "10px", width: "50%" }}>
                        <span className="title-text-underline">
                          {item.name}
                        </span>
                      </td>
                      <td style={{ padding: "10px", width: "30%" }}>
                        <span>{item.description}</span>
                      </td>
                      <td>
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <button
                            className="button-edit"
                            onClick={() => redirectToEdit(item)}
                          >
                            Editar
                          </button>
                          <button
                            className="button-delete"
                            style={{ marginRight: 5 }}
                            onClick={() => handleOpenModalDelete(item)}
                          >
                            Excluir
                          </button>
                        </div>
                      </td>
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
          {ipcc2019?.itens.length > 0 && (
            <Pagination
              total={ipcc2019.total}
              limit={25}
              goNext={goNext}
              goPrev={goPrev}
              currentPage={currentPage}
              length={ipcc2019.itens.length}
            />
          )}
        </div>
      )}

      {openModalDelete && (
        <ModalDelete
          isOpen={openModalDelete}
          handleModalVisible={handleOpenModalDelete}
          entityName={ipcc2019Selected.name}
          handleOk={handleDeleteIPCC2019}
        />
      )}
    </Container>
  );
};
