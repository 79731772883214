import { useEffect, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { Button } from "../../../components/Button/styles";
import { GoBack } from "../../../components/GoBack";
import { TitleText } from "../../../components/TitleText";
import useAuth from "../../../hooks/useAuth";
import companyService from "../../../services/companyService";
import { Container } from "./styles";
import { transformCNPJ } from "../../../utils/transform";
import { useLocation, useHistory } from "react-router-dom";
import { toast } from "react-hot-toast";

interface Inputs {
  company_info: string;
  company_vision: string;
  company_values: string;
  company_mission: string;
}

export const CompanyInfo = () => {
  const history = useHistory();

  const [companySelected, setCompanySelected] = useState<any>({});
  const location = useLocation();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [errorApi, setErrorApi] = useState<string | undefined>();
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [anonymize, setAnonymize] = useState(false);
  const [onlyView, setOnlyView] = useState(false);
  const [remainingChars, setRemainingChars] = useState<Record<keyof Inputs, number>>({
    company_info: 215,
    company_vision: 215,
    company_values: 215,
    company_mission: 215,
  });

  useEffect(() => {
    const item:any = location?.state;

    if (item && item.company_id){
      getCompanysById(item.company_id)

      setAnonymize(user.pseudo_anonymize)
      setOnlyView(user.profile !== 'sinergia' && user.profile !== 'admin' && user.profile !== 'analyst')
    }else{
      history.push({
        pathname: '/empresa',
      });
    }
  }, // eslint-disable-next-line react-hooks/exhaustive-deps 
  [location]);

  const { user } = useAuth();

  const {
    register,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<Inputs>();

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = event.target;
    const maxLength = 215;
    const trimmedValue = value.slice(0, maxLength);
    const remaining = maxLength - trimmedValue.length;
    setRemainingChars((prevChars) => ({
      ...prevChars,
      [name as keyof Inputs]: remaining,
    }));
    setValue(name as keyof Inputs, trimmedValue);
  };

  const onSubmit: SubmitHandler<Inputs> = async (formData) => {
    if (formData) {
      const newCompany = {
        company_info: formData.company_info || "-",
        company_vision: formData.company_vision || "-",
        company_values: formData.company_values || "-",
        company_mission: formData.company_mission || "-",
      };

      if (companySelected?.id) {
        const result = await companyService.updateInfo(
          companySelected.id,
          newCompany
        );

        if (result && !result.error) {
          //history.goBack()

          //reset();
          //setErrorApi(undefined);
          
          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      } else {
        const result = await companyService.create(newCompany);

        if (result && !result.error) {
          history.goBack()

          reset();
          setErrorApi(undefined);

          toast.success("Operação realizada com sucesso!")
        }

        if (result.error) {
          setErrorApi(result.error);
        }
      }
    }
  };

  async function getCompanysById(id: number) {
    const company = await companyService.getById(id);

    setValue("company_info", company.company_info || "-")
    setValue("company_vision", company.company_vision || "-")
    setValue("company_values", company.company_values || "-")
    setValue("company_mission", company.company_mission || "-")

    setCompanySelected(company)
  }

  return (
    <Container>
      <GoBack />

      <form onSubmit={handleSubmit(onSubmit)} style={{ display: "flex", flexDirection: "column" }} >
        <div style={{ display: "flex", flexDirection: "row", marginTop: 15, justifyContent: "space-between", marginBottom: 10 }} >
          <TitleText level={1} title={companySelected.corporate_name + ' (' + transformCNPJ(companySelected.cnpj) + ')'}  />
          
          {onlyView === false && (
            <Button color="green" size="md" type="submit">
              Salvar
            </Button>
          )}
        </div>

        <div style={{ width: "100%", borderTop: "0.6px solid rgba(49, 54, 63, 0.35)"}} />

        <div style={{ display: "flex", flexDirection: "column", width: "90%", marginTop: 10, marginLeft: 50, marginRight: 50  }} >

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Informações institucionais
          </label>

          <textarea style={{width: '95%', height: 100}} disabled={onlyView}
            {...register("company_info")}/>
                      
          {errors.company_info && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Missão ({remainingChars.company_mission})
          </label>

          <textarea style={{width: '95%', height: 100}} disabled={onlyView}
            {...register("company_mission")}
            onChange={handleInputChange}
            />
                      
          {errors.company_mission && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Visão ({remainingChars.company_vision})
          </label>

          <textarea style={{width: '95%', height: 100}} disabled={onlyView}
            {...register("company_vision")}
            onChange={handleInputChange}/>
                      
          {errors.company_vision && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}

          <label style={{ marginTop: 15, fontFamily: "'Roboto', sans-serif", fontSize: "16px", 
            lineHeight: "19px", color: "#31363F", opacity: "0.8", fontWeight: "bold" }} >
              Valores ({remainingChars.company_values})
          </label>

          <textarea style={{width: '95%', height: 100}} disabled={onlyView}
            {...register("company_values")}
            onChange={handleInputChange}/>
                      
          {errors.company_values && (
              <span className='requiredLabel' >Esse campo é obrigatório</span>
          )}
        </div>
      </form>
    </Container>
  );
};
