import Gas from "../../assets/icons/gas.svg";
import Car from "../../assets/icons/car.svg";
import Bell from "../../assets/icons/bell.svg";
import Mail from "../../assets/icons/mail.svg";
import Zone from "../../assets/icons/zone.svg";
import Cold from "../../assets/icons/cold.svg";
import Help from "../../assets/icons/help.svg";
import Macc from "../../assets/icons/macc.svg";
import Rain from "../../assets/icons/rain.svg";
// import Home from "../../assets/icons/home.svg";
import User from "../../assets/icons/user.svg";
import Fuel from "../../assets/icons/fuel.svg";
import Graph from "../../assets/icons/graph.svg";
import Train from "../../assets/icons/train.svg";
import Unity from "../../assets/icons/unity.svg";
import Energy from "../../assets/icons/energy.svg";
import Ticket from "../../assets/icons/ticket.svg";
import Airport from "../../assets/icons/airport.svg";
import Group from "../../assets/icons/clipboard.svg";
import Company from "../../assets/icons/company.svg";
import Check from "../../assets/icons/check_icon.svg";
import Contract from "../../assets/icons/contract.svg";
import Template from "../../assets/icons/template.svg";
import Dashboard from "../../assets/icons/dashboard.svg";
import Inventory from "../../assets/icons/inventory.svg";
import Transport from "../../assets/icons/transport.svg";
import Briefcase from "../../assets/icons/briefcase.svg";
import HomeOffice from "../../assets/icons/home-office.svg";
import VehicleFuel from "../../assets/icons/vehicle_fuel.svg";
import IPCCEffluents  from "../../assets/icons/chartt.png";
import IPCCEffluents2006 from "../../assets/icons/chartt.png"

import { IMenu } from "../../typings/Menu";

export function linksSinergia() {
  const menu: IMenu[] = [
    // {
    //   path: "/inicio", icon: Inventory, name: "Inicio", active: true,
    // },
    { 
      path: "/inventario", icon: Inventory, name: "Inventário", active: true,
    },
    // { 
    //   path: "/dashboard", icon: Dashboard, name: "Dashboard", active: true,
    // },
    // {
    //    path: "/resumo", icon: HomeOffice, name: "Resumo", active: true,
    // },
    {
      path: "/rpe", icon: HomeOffice, name: "RPE - GHG Protocol", active: true,
    },
    {
      path: "/curva-macc", icon: Macc, name: "Descarbonização", active: true,
    },
    // {
    //   path: "/indicadores", icon: Dashboard, name: "Indicadores", active: true,
    // },
    {
      path: "-", icon: "", name: "", active: true,
    },
    {
      path: "/email", icon: Mail, name: "Configurações de E-mail", active: true,
    },
    {
      path: "/termos-de-uso", icon: Contract, name: "Termos de Uso", active: true,
    },
    {
      path: "/empresa", icon: Company, name: "Empresas", active: true,
    },
    {
      path: "/usuario", icon: User, name: "Usuários", active: true,
    },
    {
      path: "/auditoria", icon: Check, name: "Histórico", active: true,
    },
    {
      path: "-", icon: "", name: "", active: true,
    },
    {
      path: "/aeroporto", icon: Airport, name: "Aeroportos", active: true,
    },
    {
      path: "/caracteristicas-climaticas", icon: Rain, name: "Características climáticas", active: true,
    },
    {
      path: "/combustivel", icon: Fuel, name: "Combustíveis", active: true,
    },
    {
      path: "/conssessionaria-trem", icon: Train, name: "Conssessionarias de trem", active: true,
    },
    {
      path: "/combustivel-particular", icon: VehicleFuel, name: "Composições de combustível", active: true,
    },
    {
      path: "/dados-sazonais", icon: Graph, name: "Dados Sazonais", active: true,
    },
    {
      path: "/escopo-inventario", icon: Template, name: "Escopos do inventário", active: true,
    },
    {
      path: "/gas", icon: Gas, name: "Gases do efeito estufa", active: true,
    },
    {
      path: "/home-office", icon: HomeOffice, name: "Home Office (Consumo)", active: true,
    },
    {
      path: "/organismo", icon: Group, name: " Organismos Verificadores", active: true,
    },
    {
      path: "/refrigeracao", icon: Cold, name: "Refrigeração", active: true,
    },
    {
      path: "/setor", icon: Zone, name: "Setores econômicos", active: true,
    },
    {
      path: "/setor-combustao", icon: Briefcase, name: "Setores de combustão", active: true,
    },
    {
      path: "/tipo-chamado", icon: Ticket, name: "Tipos de chamado", active: true,
    },
    {
      path: "/tipo-energia", icon: Energy, name: "Tipos de energia", active: true,
    },
    {
      path: "/tipo-frota", icon: Car, name: "Tipos de frota de veículos", active: true,
    },
    {
      path: "/tipo-tratamento", icon: Bell, name: "Tipos de tratamento", active: true,
    },
    {
      path: "/ipcc-efluentes", icon: IPCCEffluents, name: "IPCC 2019 - Efluentes", active: true,
    },
    {
      path: "/ipcc-efluentes-2006", icon: IPCCEffluents2006, name: "IPCC 2006 - Efluentes", active: true,
    },
    {
      path: "/tipo-transporte", icon: Transport, name: "Tipos de transporte", active: true,
    },
    {
      path: "/topico-ajuda", icon: Help, name: "Tópicos de ajuda", active: true,
    },
    {
      path: "/unidade", icon: Unity, name: "Unidades", active: true,
    },
  ];

  return menu;
}
