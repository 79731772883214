import { api } from "./api";

async function getById(ipcc_Id: number) {
  try {
    const { data } = await api.get(`/ipcc_effluents/${ipcc_Id}`);
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByIdEffluentsType(type_Id: number) {
    try {
      const { data } = await api.get(`/ipcc_effluents/effluents_type/${type_Id}`);
      return data
    } catch (error) {
      console.log(error);
    }
  }

async function getByParams(params: any) {
  try {
    const { data } = await api.get("/ipcc_effluents", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function getByParamsEffluentsType(params: any) {
  try {
    const { data } = await api.get("/ipcc_effluents/effluents_type", {
      params: params
    });
    return data
  } catch (error) {
    console.log(error);
  }
}

async function create(ipcc_effluents: any) {
  try {
    const { data } = await api.post(`/ipcc_effluents`, ipcc_effluents);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function createEffluentType(ipcc_effluents: any) {
  try {
    const { data } = await api.post(`/ipcc_effluents/effluents_type`, ipcc_effluents);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateById(ipcc_IdId: number, ipcc_effluents: any) {
  try {
    const { data } = await api.put(`/ipcc_effluents/${ipcc_IdId}`, ipcc_effluents);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function updateEffluentTypeById(ipcc_IdId: number, ipcc_effluents: any) {
  try {
    const { data } = await api.put(`/ipcc_effluents/effluents_type/${ipcc_IdId}`, ipcc_effluents);
    return data;
  } catch (error: any) {
    return { error: error?.response?.data?.message }
  }
}

async function deleteById(ipcc_Id: number) {
  try {
    const { data } = await api.delete(`/ipcc_effluents/${ipcc_Id}`);
    return data;
  } catch (error) {
    return false;
  }
}

async function deleteEffluentsTypeById(ipcc_Id: number) {
  try {
    const { data } = await api.delete(`/ipcc_effluents/effluents_type/${ipcc_Id}`);
    return data;
  } catch (error) {
    return false;
  }
}

const IpccService = {
  getById,
  getByIdEffluentsType,
  getByParams,
  getByParamsEffluentsType,
  create,
  createEffluentType,
  updateById,
  updateEffluentTypeById,
  deleteById,
  deleteEffluentsTypeById
};

export default IpccService;
